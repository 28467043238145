import React from 'react';
import Anchor from '../form-elements/Anchor';
import classnames from 'classnames';

function CardSlider({ children }) {
    return (
         <div className="uk-padding uk-slider" data-uk-slider="">
            <div className="uk-position-relative" tabindex="-1">
                <ul className="uk-slider-items uk-grid uk-grid-match uk-child-width-1-2@s uk-child-width-1-3@m">
                    {children}
                </ul>
                <Anchor className="uk-position-center-left-out" data-uk-slider-item="previous">
                    <i data-uk-slidenav-previous="" />
                </Anchor>
                <Anchor className="uk-position-center-right-out" data-uk-slider-item="next">
                    <i data-uk-slidenav-next="" />
                </Anchor>
            </div>
            <ul className={classnames('uk-slider-nav uk-dotnav uk-flex-center@s uk-margin', children.length > 12? "uk-flex-between" : "uk-flex-center fewerDots")}></ul>
        </div>
    );
}

export default CardSlider;
